/**
 * external libs
 */
import React, { useContext } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
/**
 * components
*/
import Button from "../../../common-components/button/Button";
import Field from "../../../common-components/field/Field";
import Input from "../../../common-components/input/Input";
import DropDown from "../../../common-components/drop-down/DropDown";
/**
 * context
 */
import { GlobalContext } from './../../../App'
/**
 * utils
 */
import { topUpAccountSchema } from "./yup.account";
import useSender from './../../../utils/sender'
/**
 * styles
*/
import styles from './../../layouts.module.scss';
/**
 * types
*/
import { SelectOptionType, GlobalContextType } from "./../../../types";

type TopUpAccountFormType = {
    replenishment_amount: number;
    fuel_type: number | null;
    card: string | null;
    // nds_type: number | null;
};

const TopUpAccountPhis: React.FC<{ close: Function }> = ({ close }) => {
    const { addAlert, petrol, cards } = useContext<GlobalContextType>(GlobalContext)
    const { http } = useSender()

    const {
        register,
        handleSubmit,
        control,
        setError,
        formState: { errors },
        watch,
        setValue
    } = useForm<TopUpAccountFormType>({
        mode: "onBlur",
        resolver: yupResolver<TopUpAccountFormType>(topUpAccountSchema),
        defaultValues: {
            replenishment_amount: 0,
            fuel_type: null,
            card: null,
        },
    });

    const submitHandler: SubmitHandler<TopUpAccountFormType> = async (formdata) => {
        console.log("formdata", formdata);
        try {
            await http.post("/payments", {
                petrol: formdata.fuel_type,
                cardNumber: formdata.card,
                amount: formdata.replenishment_amount,
            })

            close()
        } catch (e) {
            if (addAlert) {
                addAlert({ text: 'Ошибка запроса', type: 'error' })
            }
        }
    }

    return (
        <form
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(submitHandler)}
            className={styles.form}
        >
            <div className={styles.form__container}>
                <Controller
                    name="fuel_type"
                    control={control}
                    render={({ field }) => (
                        <Field
                            label="Топливо"
                            error={errors[field.name]?.message}
                        >
                            <DropDown
                                values={field.value ? (petrol || []).filter(option => option.id === field.value) : []}
                                changeHandler={vals => field.onChange(vals?.[0].id || null)}
                                placeholder="Топливо"
                                isGray={true}
                                options={(petrol || []).map(option => ({id: option.id, label: option.name}))}
                            />
                        </Field>
                    )}
                />

                <Controller
                    name="replenishment_amount"
                    control={control}
                    render={({ field }) => (
                        <Field
                            label="Сумма пополнения, руб"
                            error={errors[field.name]?.message}
                        >
                            <Input
                                placeholder="Сумма пополнения"
                                value={String(field.value) || ""}
                                change={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const trimVal: string = (e.target.value || "").trim();
                                    //@ts-ignore
                                    if(!isNaN(trimVal)) {
                                        field.onChange(Number(trimVal));
                                    }
                                }}
                            />
                        </Field>
                    )}
                />

                <Controller
                    name="card"
                    control={control}
                    render={({ field }) => (
                        <Field
                            label="Карта"
                            error={errors[field.name]?.message}
                        >
                            <DropDown
                                values={field.value ? (cards || []).filter(option => option.id === field.value) : []}
                                changeHandler={vals => field.onChange(vals?.[0].id || null)}
                                placeholder="Выбирите карту"
                                isGray={true}
                                options={(cards || []).map(option => ({id: option.id, label: option.cardNumber}))}
                            />
                        </Field>
                    )}
                />

                <Field
                    label="Сумма пополнения, руб"
                    noError={true}
                >
                    <div className={styles.form__textWrapper}>
                        <p className={styles.form__text}>10л. <span className={styles.form__text_green}>+1 л</span></p>

                        <svg className={styles.form__icoInfo} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.0002 16.9995H13.0002V10.9995H11.0002V16.9995ZM12.0002 8.99951C12.2836 8.99951 12.5212 8.90351 12.7132 8.71151C12.9052 8.51951 13.0009 8.28218 13.0002 7.99951C13.0002 7.71618 12.9042 7.47884 12.7122 7.28751C12.5202 7.09618 12.2829 7.00018 12.0002 6.99951C11.7169 6.99951 11.4796 7.09551 11.2882 7.28751C11.0969 7.47951 11.0009 7.71684 11.0002 7.99951C11.0002 8.28284 11.0962 8.52051 11.2882 8.71251C11.4802 8.90451 11.7176 9.00018 12.0002 8.99951ZM12.0002 21.9995C10.6169 21.9995 9.31691 21.7368 8.10024 21.2115C6.88358 20.6862 5.82524 19.9738 4.92524 19.0745C4.02524 18.1745 3.31291 17.1162 2.78824 15.8995C2.26358 14.6828 2.00091 13.3828 2.00024 11.9995C2.00024 10.6162 2.26291 9.31618 2.78824 8.09951C3.31358 6.88284 4.02591 5.82451 4.92524 4.92451C5.82524 4.02451 6.88358 3.31218 8.10024 2.78751C9.31691 2.26285 10.6169 2.00018 12.0002 1.99951C13.3836 1.99951 14.6836 2.26218 15.9002 2.78751C17.1169 3.31284 18.1752 4.02518 19.0752 4.92451C19.9752 5.82451 20.6879 6.88284 21.2132 8.09951C21.7386 9.31618 22.0009 10.6162 22.0002 11.9995C22.0002 13.3828 21.7376 14.6828 21.2122 15.8995C20.6869 17.1162 19.9746 18.1745 19.0752 19.0745C18.1752 19.9745 17.1169 20.6872 15.9002 21.2125C14.6836 21.7378 13.3836 22.0002 12.0002 21.9995ZM12.0002 19.9995C14.2336 19.9995 16.1252 19.2245 17.6752 17.6745C19.2252 16.1245 20.0002 14.2328 20.0002 11.9995C20.0002 9.76618 19.2252 7.87451 17.6752 6.32451C16.1252 4.77451 14.2336 3.99951 12.0002 3.99951C9.76691 3.99951 7.87524 4.77451 6.32524 6.32451C4.77524 7.87451 4.00024 9.76618 4.00024 11.9995C4.00024 14.2328 4.77524 16.1245 6.32524 17.6745C7.87524 19.2245 9.76691 19.9995 12.0002 19.9995Z" />
                        </svg>
                    </div>
                </Field>

                <Field
                    label="Цена за литр"
                    noError={true}
                >
                    <div className={styles.form__textWrapper}>
                        <p className={styles.form__text}>АИ-95 / 30р.</p>
                    </div>
                </Field>
            </div>

            <Button
                label="Пополнить баланс"
                type="submit"
                subClasses={styles.form__submit}
            />
        </form>
    );
};

export default TopUpAccountPhis;