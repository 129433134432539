/**
 * external libs
 */
import React, { useContext, useState, useCallback, useMemo, useEffect } from 'react'
/**
 * components
 */
import CheckBox from './../../common-components/checkbox/CheckBox'
import Button from './../../common-components/button/Button'
/**
 * context
 */
import { MapGlobalContext } from './MapContext'
import { GlobalContext } from './../../App'
/**
 * utils
 */
import useSender from './../../utils/sender'
import MapPortal from './../../common-components/portal/MapPortal'
/**
 * types
 */
import { MapGlobalContextType, GlobalContextType, ServiceType } from './../../types'
/**
 * styles
 */
import styles from './map.module.scss'
import layoutStyles from './../../layouts/layouts.module.scss'

// const fuelTypes = [
//     { id: "1", name: "АИ-92", color: "#F4BF00" },
//     { id: "2", name: "АИ-95", color: "#E20A0A" },
//     { id: "3", name: "ДТ", color: "#374151" },
//     { id: "4", name: "АИ-95+", color: "#34AA0A" },
//     { id: "5", name: "АИ-92", color: "#F4BF00" },
//     { id: "6", name: "АИ-100", color: "#0D38CE" },
//     { id: "7", name: "ДТ зимнее", color: "#374151" },
// ];

// const checkboxes = [
//     { id: "1", label: "Мойка" },
//     { id: "2", label: "Подкачка колёс" },
//     { id: "3", label: "Заправка «омывайки»" },
//     { id: "4", label: "Кафе" },
// ];

const StanchionInfoComponent: React.FC = () => {
    const { http } = useSender()

    const { addAlert } = useContext<GlobalContextType>(GlobalContext)
    const { fuelFilter, setFuelFilter, servicesFilter, setServicesFilter } =
        useContext<MapGlobalContextType>(MapGlobalContext)

    const [fuelTypes, setFuelTypes] = useState<ServiceType[] | null>(null)
    const [checkboxes, setCheckboxes] = useState<ServiceType[] | null>(null)

    const isFilter = useMemo(() => {
        return !!fuelFilter.length || !!servicesFilter.length
    }, [fuelFilter, servicesFilter])

    const submitHandler = useCallback(() => {}, [])

    const resetHandler = useCallback(() => {
        setServicesFilter([])
        setFuelFilter([])
    }, [setServicesFilter, setFuelFilter])

    const servicesFilterHandler = useCallback(
        (id: string) => {
            setServicesFilter((pre) => {
                if (pre.includes(id)) {
                    return pre.filter((i) => i !== id)
                }

                return [...pre, id]
            })
        },
        [setServicesFilter]
    )

    const chooseHandler = (id: string) => {
        setFuelFilter((pre) => {
            if (pre.includes(id)) {
                return pre.filter((i) => i !== id)
            }

            return [...pre, id]
        })
    }

    useEffect(() => {
        const getColor = (nameService: string) => {
            switch (nameService) {
                case 'ДТАрк':
                case '98':
                    return '#0D38CE'
                case '80':
                case '95':
                    return '#E20A0A'
                case 'V-Power':
                case '95+':
                    return '#34AA0A'
                case '92':
                    return '#F4BF00'
                case 'ДТл':
                    return '#374151'
                default:
                    return '#374151'
            }
        }

        const getListData = async () => {
            try {
                const { data } = await http.get("/stations/services")

                setFuelTypes(
                    (data as string[]).map((service: string) => ({
                        service,
                        color: getColor(service),
                    }))
                )

                //!
                setCheckboxes([])
            } catch (e) {
                if (addAlert) {
                    addAlert({ text: 'Ошибка запроса', type: 'error' })
                }
            }
        }

        getListData()
    }, [setFuelTypes, setCheckboxes, addAlert])

    if (fuelTypes === null || checkboxes === null) {
        return null
    }

    return (
        <div className={`${layoutStyles.officeLayout__drawer} ${layoutStyles.officeLayout__drawer_info}`}>
            <div className={layoutStyles.officeLayout__drawerScroll}>
                <div className={styles.stanchionInfo}>
                    <div className={styles.stanchionInfo__chosen}>
                        <p className={styles.stanchionInfo__title}>Топливо</p>
                        <p className={styles.stanchionInfo__subData}>
                            Выбрано: <span className={styles.stanchionInfo__subData_blue}>{fuelFilter.length}</span>
                        </p>
                    </div>

                    <div className={styles.stanchionInfo__fuelTypes}>
                        {(fuelTypes || []).map((fType) => (
                            <div
                                key={fType.service}
                                className={`${styles.stanchionInfo__fuelType} ${fuelFilter.includes(fType.service) ? styles.active : ''}`}
                                onClick={() => chooseHandler(fType.service)}>
                                <div
                                    className={styles.stanchionInfo__fuelColor}
                                    style={{ backgroundColor: fType.color }}
                                />

                                <p className={styles.stanchionInfo__fuelName}>{fType.service}</p>
                            </div>
                        ))}
                    </div>

                    <p className={styles.stanchionInfo__title}>Услуги на АЗС</p>

                    <div className={`${styles.stanchionInfo__options} ${styles.open}`}>
                        {(checkboxes || []).map((checkbox) => (
                            <CheckBox
                                key={checkbox.service}
                                isActive={servicesFilter.includes(checkbox.service)}
                                label={checkbox.service}
                                handler={() => servicesFilterHandler(checkbox.service)}
                            />
                        ))}
                    </div>

                    <div className={styles.stanchionInfo__result}>
                        <p className={styles.stanchionInfo__subData}>Найдено 142</p>

                        <Button
                            label="Найти"
                            type="button"
                            subClasses={`${styles.stanchionInfo__button} ${!isFilter ? styles.stanchionInfo__button_disable : ''}`}
                            handler={submitHandler}
                        />

                        <Button
                            label="Сбросить всё"
                            type="button"
                            subClasses={`${styles.stanchionInfo__button} ${styles.stanchionInfo__button_red} ${!isFilter ? styles.stanchionInfo__button_disable : ''}`}
                            handler={resetHandler}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

const StanchionInfo: React.FC = () => {
    const { filterOpen, setFilterOpen } = useContext<MapGlobalContextType>(MapGlobalContext)
    const [mobile, setMobile] = useState(window.innerWidth <= 850)

    useEffect(() => {
        const checkWidth = () => {
            setMobile(window.innerWidth <= 850)
        }

        window.addEventListener('resize', checkWidth)

        return () => {
            window.removeEventListener('resize', checkWidth)
        }
    }, [setMobile])

    if (mobile) {
        return (
            <MapPortal open={filterOpen} close={() => setFilterOpen(false)}>
                <StanchionInfoComponent />
            </MapPortal>
        )
    }

    return <StanchionInfoComponent />
}

export default StanchionInfo
