/**
 * external libs
 */
import React, { useContext } from 'react'
/**
 * context
 */
import { GlobalContext } from './../../App'
/**
 * utils
 */
import useSender from './../../utils/sender'
/**
 * types
 */
import { GlobalContextType, RolesType } from './../../types'
/**
 * styles
 */
import styles from './../layouts.module.scss'

const Sync: React.FC = () => {
    const { http } = useSender()
    const { addAlert, user } = useContext<GlobalContextType>(GlobalContext)

    const syncHandler = async () => {
        //! Mock
        // if(user?.Role && user?.uID) {
        //     try {
        //         const { data } = await http.get(`/${user.Role}/${user.uID}/wallets`)
        //     } catch (e) {
        //         if (addAlert) {
        //             addAlert({ text: 'Ошибка запроса', type: 'error' })
        //         }
        //     }
        // }
    }

    return (
        <div className={styles.carts}>
            {user?.Role === RolesType.managers && (
                <button className={styles.cart__button} onClick={syncHandler} type="button">
                    Синхронизировать с СУТК
                </button>
            )}
        </div>
    )
}

export default Sync
