/**
 * external libs
 */
import React, { PropsWithChildren, useState, useEffect, useContext, useCallback, useMemo } from 'react'
import { Outlet, Route, Routes, BrowserRouter, Navigate, useLocation } from "react-router-dom";
import { Helmet } from 'react-helmet-async'
/**
 * components
 */
import Menu from './Menu'
import Sync from './Sync'
import avatar from './../../assets/icons/avatar.svg'
import logout from './../../assets/icons/log-out.svg'
/**
 * context
 */
import { GlobalContext } from './../../App'
/**
 * utils
 */
import useSender from './../../utils/sender'
import { manageRoutes } from '../../routers/paths'
/**
 * types
 */
import { GlobalContextType, RolesTranslateType, RouteType, RolesType } from './../../types'
/**
 * styles
 */
import styles from './../layouts.module.scss'

const ManagerLayout: React.FC<PropsWithChildren> = () => {
    const { user, changeUser } =
        useContext<GlobalContextType>(GlobalContext)
    const location = useLocation()
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false)
    const [access, setAccess] = useState(false)
    const { logIn, logOut } = useSender()

    const routeData = useMemo<RouteType | undefined>(
        () => manageRoutes.find((r: RouteType) => r.path === location.pathname),
        [location]
    )

    const firstVisitHandler = useCallback(() => {
        if (user?.uID && user?.Role && (user.Role === RolesType.operator || user.Role === RolesType.managers)) {
            setAccess(true)
        } else {
            const accessToken = sessionStorage.getItem('accessToken') || localStorage.getItem('accessToken')
            const refreshToken = sessionStorage.getItem('refreshToken') || localStorage.getItem('refreshToken')
            const uIDLocal = localStorage.getItem('uid')
            const roleLocal = localStorage.getItem('urole')
            const unameLocal = localStorage.getItem('uname')
            

            if (accessToken && refreshToken && uIDLocal && roleLocal && changeUser) {
                logIn(accessToken, refreshToken, uIDLocal, roleLocal, unameLocal || '')
                changeUser({
                    Role: roleLocal as keyof typeof RolesType,
                    uID: uIDLocal,
                    uName: unameLocal,
                })

                setAccess(true)
            } else {
                logOut()
            }
        }
        setAccess(true)
    }, [user, setAccess])

    useEffect(() => {
        firstVisitHandler()
    }, [firstVisitHandler])

    if (!access) {
        return null
    }

    return (
        <>
            <Helmet>
                <title>{routeData?.title ?? ''}</title>
                <meta name="description" content={routeData?.description ?? ''} />
            </Helmet>

            <div className="container">
                <div className={styles.officeLayout}>
                    <div className={styles.officeLayout__drawerWrapper}>
                            <div
                                className={`${styles.officeLayout__drawer} ${drawerOpen ? styles.officeLayout__drawer_open : ''}`}>
                                <div className={styles.officeLayout__drawerScroll}>
                                    <div className={styles.officeLayout__opener}>
                                        <button
                                            type="button"
                                            className={styles.officeLayout__openButton}
                                            onClick={() => setDrawerOpen((pre) => !pre)}>
                                            <svg
                                                className={styles.officeLayout__openIco}
                                                viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M9.6001 16.8L14.4001 12L9.6001 7.20005"
                                                    strokeWidth="1.8"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </button>
                                    </div>

                                    <Menu />
                                    <Sync />

                                    <div className={styles.user}>
                                        <div className={styles.user__logoWrap}>
                                            <img src={avatar} alt="avatar" />
                                        </div>

                                        <div className={styles.user__info}>
                                            <p className={styles.user__label}>{user?.uName || ''}</p>
                                            {!!user?.Role && <p className={styles.user__name}>{RolesTranslateType[user?.Role]}</p>}
                                        </div>

                                        <button type="button" className={styles.user__button} onClick={logOut}>
                                            <img src={logout} alt="log-out" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                    </div>

                    <div className={styles.officeLayout__content}>
                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ManagerLayout
