/**
 * components
*/
import Home from "../pages/home/HomeLazy";
import Office from "../pages/office/OfficeLazy";
import Recovery from "../pages/recovery/RecoveryLazy";
import Alerts from "../pages/alerts/AlertsLazy";
import Profile from "../pages/profile/ProfileLazy";
import FuelCards from "../pages/fuel-cards/FuelCardsLazy";
import Map from "../pages/map/MapLazy";
import FuelCardsSingle from "../pages/fuel-cards-Single/FuelCardsSingleLazy";
import ManageCompanies from "../pages/manage/companies/CompaniesLazy";
import ManageIndividuals from "../pages/manage/individuals/IndividualsLazy";
import ManageAppeals from "../pages/manage/appeals/AppealsLazy";
import ManagePush from "../pages/manage/push/PushLazy";
import ManageGasStations from "../pages/manage/gas-stations/GasStationsLazy";
import ManageAdmin from "../pages/manage/admin/AdminLazy";
/**
 * types
*/
import { RouteType } from "../types";

export const publicRoutes: RouteType[] = [
    { path: "/", exact: true, component: Home, title: "Home", description: "Home" },
    { path: "/recovery", exact: true, component: Recovery, title: "Recovery", description: "Recovery" },
];

export const privateRoutes: RouteType[] = [
    { path: "/office", exact: true, component: Office, title: "Office", description: "Office" },
    { path: "/alerts", exact: true, component: Alerts, title: "Alerts", description: "Alerts" },
    { path: "/appeals", exact: true, component: Alerts, title: "Appeals", description: "Appeals" },
    { path: "/profile", exact: true, component: Profile, title: "Profile", description: "Profile" },
    { path: "/fuel-cards", exact: true, component: FuelCards, title: "Fuel Cards", description: "Fuel Cards" },
    { path: "/map", exact: true, component: Map, title: "Map", description: "Map" },
    { path: "/fuel-cards/:id", exact: true, component: FuelCardsSingle, title: "Fuel Cards", description: "Fuel Cards" },
];

export const manageRoutes: RouteType[] = [
    { path: "", exact: true, component: ManageCompanies, title: "Manage companies", description: "Manage companies" },
    { path: "individuals", exact: true, component: ManageIndividuals, title: "Manage individuals", description: "Manage individual" },
    { path: "appeals", exact: true, component: ManageAppeals, title: "Manage Appeals", description: "Manage Appeals" },
    { path: "push", exact: true, component: ManagePush, title: "Manage Push", description: "Manage Push" },
    { path: "gas-stations", exact: true, component: ManageGasStations, title: "Manage gas stations", description: "Manage gas stations" },
    { path: "admin", exact: true, component: ManageAdmin, title: "Admin", description: "Admin" },
];

