/**
 * external libs
 */
import React, { useCallback, useEffect, useState, useContext } from "react";
import Slider from "react-slick";
/**
 * components
*/
import MenuCard from "./MenuCard";
import TopUpAccountPhis from "./phis/TopUpAccountPhis";
import TopUpAccountEntity from "./entity/TopUpAccountEntity";
import TopUpAccountDriver from "./driver/TopUpAccountDriver";
import Portal from "./../../common-components/portal/Portal";
/**
 * context
*/
import { GlobalContext } from "./../../App";
/**
 * utils
 */
import { useAllData }  from './../../utils/all-data'
/**
 * types
*/
import { RolesType, GlobalContextType, CardType, WalletType, BalanceType } from "./../../types";
/**
 * styles
*/
import styles from './../layouts.module.scss';

const Carts: React.FC = () => {
    const { getAllData } = useAllData()

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        dotsClass: styles.carts__dots,
    };

    const [portalOpen, setPortalOpen] = useState<boolean>(false);
    const [portalData, setPortalData] = useState<null | any>(null);
    const { addAlert, user, cards } = useContext<GlobalContextType>(GlobalContext)
    const [walletCards, setWalletCards] = useState<(Partial<WalletType> & CardType)[] | null>(null)
    const [balance, setBalance] = useState<number | null>(null)

    const closePortal = useCallback(() => {
        setPortalData(null);
    }, [setPortalData]);

    useEffect(() => {
        setPortalOpen(!!portalData);
    }, [portalData, setPortalOpen]);

    const getCards = async () => {
        if(user?.Role && user?.uID) {
            try {
                const balanceObj: BalanceType[] | null = user.Role === RolesType.drivers
                    ? null
                    : await getAllData<BalanceType>(`/${user.Role}/${user.uID}/wallets`)

                const walletsArray: WalletType[][] = user.Role === RolesType.drivers
                    ? [[]]
                    : await Promise.all((cards || []).map((card: CardType) =>
                        getAllData<WalletType>(`/${user.Role}/${user.uID}/cards/${card.id}/wallets`)
                    ))

                const result: (Partial<WalletType> & CardType)[] = (cards || []).map((card: CardType, idx: number) => {
                    const wallet = (walletsArray[idx] || []).reduce((acc, walletData) => {

                        return {
                            ...acc,
                            monthlyLimit: acc.monthlyLimit + (walletData?.monthlyLimit || 0),
                            dailyLimit: acc.dailyLimit + (walletData?.dailyLimit || 0),
                            sizePurse: acc.sizePurse + (walletData?.sizePurse || 0),
                        };
                    }, {monthlyLimit: 0, dailyLimit: 0, sizePurse: 0})

                    return {
                        ...card,
                        ...wallet,
                    }
                })

                setWalletCards(result)
                setBalance((balanceObj || []).find(bal => bal.serviceName === "Рубли")?.sizeAccount || 0)
            } catch (e) {
                if (addAlert) {
                    addAlert({ text: 'Ошибка запроса', type: 'error' })
                }
            }
        }
    }

    useEffect(() => {
        getCards()
    }, [cards])

    if (walletCards === null || (balance === null && user?.Role !== RolesType.drivers)) {
        return null
    }

    return (
        <div className={styles.carts}>
            {
                !!walletCards.length &&
                    <>
                        {
                            walletCards.length === 1
                                ? <MenuCard
                                    card={walletCards[0]}
                                    setPortalData={setPortalData}
                                />
                                : <Slider {...settings}>
                                    {
                                        walletCards.map(card => (
                                            <MenuCard
                                                key={card.id}
                                                card={card}
                                                setPortalData={setPortalData}
                                            />
                                        ))
                                    }
                                </Slider>
                        }
                    </>
            }

            {
                (user?.Role === RolesType.companies || user?.Role === RolesType.individuals) &&
                <>
                    <div className={styles.carts__balance}>
                        <p className={styles.carts__text}>Остаток на счете:</p>
                        <p className={`${styles.carts__text} ${styles.carts__text_right}`}>{balance} ₽</p>
                    </div>
                </>
            }

            <Portal
                title="Пополнение баланса"
                open={portalOpen}
                close={closePortal}
            >
                {
                    user?.Role === RolesType.individuals &&
                    <TopUpAccountPhis close={closePortal}/>
                }

                {
                    user?.Role === RolesType.companies &&
                    <TopUpAccountEntity close={closePortal}/>
                }

                {
                    user?.Role === RolesType.drivers &&
                    <TopUpAccountDriver close={closePortal}/>
                }
            </Portal>
        </div>
    );
};

export default Carts;
