import * as yup from "yup";

export const topUpAccountSchema = yup.object().shape({
    replenishment_amount: yup
        .number()
        .typeError('Это поле обязательное.')
        .required("Это поле обязательное.")
        .test('numbers', 'Вы не ввели сумму', value => value !== 0),
    terms: yup
        .number()
        .typeError('Это поле обязательное.')
        .required("Это поле обязательное."),
    card: yup
        .number()
        .nullable()
        .default(0),
    nds_type: yup
        .number()
        .typeError('Это поле обязательное.')
        .required("Это поле обязательное.")
});