/**
 * external libs
*/
import React from "react";
/**
 * components
*/
import Button from "./../../../../common-components/button/Button";
import Field from "./../../../../common-components/field-public-form/Field";
import Input from "./../../../../common-components/input-public-form/Input";
/**
 * types
*/
import { CardType } from "./../../../../types";
/**
 * styles
*/
import styles from './../../../layouts.module.scss';

type Props = {
    card: CardType;
    setEditCard: React.Dispatch<React.SetStateAction<CardType | null>>;
};

const EditCard: React.FC<Props> = ({ card, setEditCard }) => {
    return (
        <div className={styles.drivers__edit}>
            <div className={styles.drivers__cardsListScroll}>
                <div className={styles.drivers__fieldListWrapper}>
                    <div className={styles.drivers__fieldTypeTag}><span>A-92</span></div>

                    <Field
                        label="Сутки"
                        error={""}
                    >
                        <Input
                            placeholder="л"
                            isError={!!""}
                            value={""}
                            change={(e: React.ChangeEvent<HTMLInputElement>) => {}}
                        />
                    </Field>

                    <Field
                        label="Неделя"
                        error={""}
                    >
                        <Input
                            placeholder="л"
                            isError={!!""}
                            value={""}
                            change={(e: React.ChangeEvent<HTMLInputElement>) => {}}
                        />
                    </Field>

                    <Field
                        label="Месяц"
                        error={""}
                    >
                        <Input
                            placeholder="л"
                            isError={!!""}
                            value={""}
                            change={(e: React.ChangeEvent<HTMLInputElement>) => {}}
                        />
                    </Field>
                </div>
            </div>

            <div className={styles.drivers__buttonsBlock}>
                <Button
                    label="Сохранить"
                    type="button"
                    subClasses={styles.drivers__button}
                    handler={() => setEditCard(null)}
                />

                <Button
                    label="Удалить лимит"
                    type="button"
                    subClasses={`${styles.drivers__button} ${styles.drivers__button_red}`}
                    handler={() => {}}
                />
            </div>
        </div>
    );
};

export default EditCard;