/**
 * external libs
*/
import React, { useContext, useCallback, useState } from "react";
/**
 * components
*/
import MapPortal from "./../../common-components/portal/MapPortal";
import Button from "../../common-components/button/Button";
/**
 * context
*/
import { MapGlobalContext } from "./MapContext";
/**
 * types
*/
import { MapGlobalContextType, GasStationType } from "./../../types";
/**
 * styles
*/
import styles from './map.module.scss';


const MapList: React.FC = () => {
    const { gasStaions, viewType, setViewType } = useContext<MapGlobalContextType>(MapGlobalContext);
    const [activeItem, setActiveItem] = useState<GasStationType | null>(null);

    const closeHandler = useCallback(() => {
        setViewType(0);
        setActiveItem(null);
    }, [setViewType, setActiveItem]);

    const getColor = (nameService: string) => {
        switch (nameService) {
            case 'Аи-98':
                return '13, 56, 206'
            case 'АИ-95':
                return '226, 10, 10'
            case 'АИ-95+':
                return '52, 170, 10'
            case 'АИ-92':
                return '244, 191, 0'
            case 'ДТ зимнее':
                return '55, 65, 81'
            case 'ДТ':
            default:
                return '55, 65, 81'
        }
    }

    return (
        <MapPortal
            open={viewType === 1}
            close={closeHandler}
            direction="left"
        >
            <div className={styles.list}>
                {
                    !!activeItem
                        ? <div className={styles.listStation}>
                            <div className={styles.listStation__location}>
                                <p className={styles.listStation__address}>{activeItem.address}</p>
                                <p className={styles.listStation__coords}>{activeItem.coordinates.join(", ")}</p>
                            </div>

                            <div className={styles.listStation__fuelContainer}>
                                <p className={styles.listStation__fuelTitle}>Топливо, Услуги АЗС</p>

                                {
                                    (activeItem.services || []).map(service => (
                                        <div key={service} className={styles.listStation__fuelLine}>
                                            <div
                                                className={styles.listStation__fuelTag}
                                                style={{backgroundColor: `rgba(${getColor(service)}, 0.15)`}}
                                            >
                                                <div
                                                    className={styles.listStation__fuelMarker}
                                                    style={{backgroundColor: `rgb(${getColor(service)})`}}
                                                />

                                                <span>{service}</span>
                                            </div>

                                            {/* <p className={styles.listStation__fuelPrice}>{service}</p> */}
                                        </div>
                                    ))
                                }
                            </div>

                            {/* <div className={styles.listStation__services}>
                                <p className={styles.listStation__fuelTitle}>Услуги АЗС</p>

                                <div className={styles.listStation__servicesContainer}>
                                    {
                                        (activeItem.services || []).map((service) => (
                                            <p key={service} className={styles.listStation__service}>
                                                {service}
                                            </p>
                                        ))
                                    }
                                </div>
                            </div> */}

                            <Button
                                handler={() => setActiveItem(null)}
                                label="Назад"
                                type="button"
                                subClasses={styles.listStation__button}
                            />
                        </div>
                        : <div className={styles.list__container}>
                            <p className={styles.list__label}>С ближайших</p>

                            {
                                (gasStaions || []).map((item) => (
                                    <div
                                        key={item.id}
                                        className={styles.listItem}
                                        onClick={() => setActiveItem(item)}
                                    >
                                        <div className={styles.listItem__name}>
                                            <span>{item.address}</span>
                                        </div>

                                        <p className={styles.listItem__address}>{item.supplier}</p>

                                        {
                                            // !!item.services[0] &&
                                            // <p className={styles.listItem__fuel}>{item.services[0].label} - {item.fuel[0].price}</p>
                                            !!item.services &&
                                            <p className={styles.listItem__fuel}>{item.services.join(", ")}</p>
                                        }
                                    </div>
                                ))
                            }
                        </div>
                }
            </div>
        </MapPortal>
    );
};

export default MapList;