/**
 * external libs
 */
import React, { PropsWithChildren, useRef, Children } from "react";
import ReactDOM from "react-dom";
import { Transition } from "react-transition-group";
/**
 * styles
*/
import styles from './portal.module.scss';

type Props = {
    title?: string | React.ReactNode;
    open: boolean;
    close: Function;
};

const Portal: React.FC<PropsWithChildren & Props> = ({ children, title, close, open = false }) => {
    const portalRef = useRef(null);
    const childrenResult = Children.toArray(children);

    return ReactDOM.createPortal(
        <Transition nodeRef={portalRef} in={open} timeout={250} unmountOnExit={false}>
            {(state: string) => {
                if (state === "exited") {
                    return null;
                }

                return (
                    <div
                        ref={portalRef}
                        className={`portal ${styles.portal} ${state === "entered" ? styles.portal_show : ""}`}
                    >
                        {/* @ts-ignore */}
                        <div className={styles.portal__container}>
                            <div className={styles.portal__header}>
                                <button
                                    className={styles.portal__close}
                                    onClick={() => close()}
                                >
                                    <svg className={styles.portal__ico} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.80005 19.7999L12 12.5999M12 12.5999L4.80005 5.3999M12 12.5999L19.2 5.3999M12 12.5999L19.2 19.7999" strokeWidth="2" strokeLinecap="round" />
                                    </svg>
                                </button>
                            </div>

                            {
                                !!title && <p className={styles.portal__title}>{title}</p>
                            }

                            {
                                !!childrenResult[1] && childrenResult[0]
                            }

                            <div className={styles.portal__scroll}>
                                {
                                    !!childrenResult[1]
                                        ? childrenResult[1]
                                        : children
                                }
                            </div>
                        </div>
                    </div>
                )
            }}
        </Transition>,
        document.body as HTMLElement
    );
};

export default Portal;
