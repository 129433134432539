/**
 * external libs
*/
import React, { useState, useContext } from "react";
/**
 * components
*/
import Driver from "../../../../common-components/driver/Driver";
import Button from "../../../../common-components/button/Button";
/**
 * context
*/
import { GlobalContext } from "./../../../../App";
/**
 * utils
 */
import useSender from './../../../../utils/sender'
/**
 * types
*/
import { GlobalContextType, DriverWithCardsType, CardType } from "./../../../../types";
/**
 * styles
*/
import styles from './../../../layouts.module.scss';

type Props = {
    driver: DriverWithCardsType;
    setActiveDriver: React.Dispatch<React.SetStateAction<DriverWithCardsType | null>>;
    setEditCard: React.Dispatch<React.SetStateAction<CardType | null>>;
    setDrivers: React.Dispatch<React.SetStateAction<DriverWithCardsType[] | null>>;
};

const ViewDriver: React.FC<Props> = ({ driver, setActiveDriver, setDrivers, setEditCard }) => {
    const { http } = useSender()
    const { addAlert, user } = useContext<GlobalContextType>(GlobalContext)
    const [confirm, setConfirm] = useState(false);

    const deleteDriver = async () => {
        if(user) {
            try {
                await http.delete(`/companies/${user.uID}/drivers/${driver.id}`)           
                setDrivers(pre => (pre || []).filter(driverItem => driverItem.id !== driver.id))
                setActiveDriver(null)
            } catch (e) {
                if (addAlert) {
                    addAlert({ text: 'Ошибка запроса', type: 'error' })
                }
            }
        }
    }

    return (
        <div className={styles.drivers__edit}>
            {
                !!confirm
                    ? <p className={styles.drivers__partTitle}>Вы точно хотите удалить этого водителя?</p>
                    : <div className={styles.drivers__partWrapper}>
                        <p className={styles.drivers__partTitle}>Топливные карты: <span className={styles.drivers__partTitle_blue}>{(driver.cards || []).length}</span></p>

                        <div className={styles.drivers__cardsListScroll}>
                            <div className={styles.drivers__cardsListWrapper}>
                                {
                                    (driver.cards || []).map(card => (
                                        <Driver key={card.id} driver={{...driver, cards: [card]}} handler={() => setEditCard(card)}/>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
            }

            <div className={styles.drivers__buttonWrapper}>
                {
                    !confirm &&
                    <Button
                        label="Назад"
                        type="button"
                        subClasses={styles.drivers__button}
                        handler={() => setActiveDriver(null)}
                    />
                }

                <Button
                    label="Удалить водителя"
                    type="button"
                    subClasses={`${styles.drivers__button} ${styles.drivers__button_red}`}
                    handler={confirm ? deleteDriver : () => setConfirm(true)}
                />
            </div>
        </div>
    );
};

export default ViewDriver;